import tick from '../../images/icons/tick.svg';
import cross from '../../images/icons/cross.svg';
import { IDomainResult } from '../../types/domain-assessment-result-types';
import editIcon from '../../images/icons/edit_ic.svg';
import deleteIcon from '../../images/icons/delete_ic.svg';
import message from '../../images/icons/message.svg';
import { useState } from 'react';


const ShowAssessmentAnswers = ({questions=[],candidateAnswers=[],candidate=false,feedback,editScore}:IDomainResult) => {

  const [isEditable,setIsEditable] = useState<boolean>(false)
  const [isSingleView,setIsSingleView] = useState<boolean>(false)
  const [selectedQuestion,setSelectedQuestion] = useState(0)

  
  return (<div className="domain-assessment-answers">
    <div className='domain-assessment-answers__header'>
      <span>
        {isSingleView &&  <span style={{ color: "#029CA5" }}>{selectedQuestion+1}{`/${questions.length}`}</span>}
        </span>
        <button style={{color:"#099C73"}} onClick={()=>{
            setIsSingleView(isSingleView===true? false : true)
            }} >{isSingleView ?  "Show all questions" : "Show one question"}
        </button>
    </div>
    {isSingleView && questions ? 
    <>
      {showQuestions(candidateAnswers,questions[selectedQuestion],candidate,feedback,editScore,isEditable,setIsEditable)}
      {questions.length >1 && 
        <div className='domain-assessment-answers__single-view'>
          <button disabled={selectedQuestion ===0} onClick={()=>setSelectedQuestion(prev=>prev-1)}>
            &larr; Previous
          </button>
          <button disabled={selectedQuestion === (questions.length-1)} onClick={()=>setSelectedQuestion(prev=>prev+1)}>
            Next &rarr;
          </button>
        </div>
      }
    </>
      :
      <>
      {questions.map(question=>{
        return showQuestions(candidateAnswers,question,candidate,feedback,editScore,isEditable,setIsEditable)
      })}
      </>
    }
  </div>)
}
export default ShowAssessmentAnswers


const showQuestions = (candidateAnswers,question,candidate,feedback,editScore,isEditable,setIsEditable)=>{
  const candidateAnswer = candidateAnswers.find(ans=>ans.questionId===question.id)
  let score=calculateScore(question.type,question.id,candidateAnswer) || 0;
  const options = showOptions(question,candidateAnswers)

  return (
    <div key={question.id}  className="domain-assessment-answers__container">
      <p className="domain-assessment-answers__container__question">{question.name}</p>
      {options && options}
        <div className='domain-assessment-answers__container__score'>
      {!candidateAnswer && "Not Answered"}
        <hr/>
          {question?.type === 'text' && question?.answers ? <p>{question.answers}</p> : ""}
          <div className={`domain-assessment-answers__container__score__content ${candidate ? "" :'candidate'}`}>
          {!candidate && 
          <> 
          {feedback && 
            <>                
              <div className="feedback-container">
                <div className="feedback-container__left">
                  {candidateAnswer?.feedback ? 
                    (
                      <>
                      <div className="show-feedback">
                        <div className="show-feedback__data" >
                          {candidateAnswer?.feedback}
                        </div>
                        <div className="show-feedback__action">
                          <img src={editIcon}  onClick={()=>{
                            feedback.setFeedbackPopup(candidateAnswer.id, candidateAnswer.feedback)
                          }}/> 
                          <img src={deleteIcon} onClick={()=>{
                            feedback.onDeleteFeedback(candidateAnswer?.id)
                          }}/>
                        </div>
                      </div>
                      </>
                    ) 
                    :(
                      <>
                        <div
                          className="add-feedback"
                          onClick={() => {
                            feedback.setFeedbackPopup(candidateAnswer.id, candidateAnswer.feedback)
                          }}>
                          <img src={message} />
                          <p>
                            Add Feedback
                          </p>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </>
          }
          {candidateAnswer && editScore && 
            <>
              <div className="feedback-container__right">
                <div className="action">
                  {isEditable ?
                    <>
                      <img src={cross} alt="" onClick={(e)=>{
                        editScore.setScore(candidateAnswer?.id,0)
                      }} />
                      <img src={tick} alt="" onClick={()=>{
                        setIsEditable(true)
                      }} />
                    </>
                  : <button onClick={()=>setIsEditable(true)}>Edit</button>}
                </div>
                <div className="marks">
                  <input type="number" defaultValue={score} disabled={!isEditable} max={10} min={0} onBlur={(e)=>{
                    setIsEditable(false)
                    editScore.setScore(candidateAnswer?.id,parseInt(e?.target?.value))
                  }}/><span> /{question?.score || 0}</span>
                </div>
              </div>
            </>
          }
          </>
          }
          {!feedback && !editScore && <pre>{question?.score && score} /{question?.score || 0}</pre>}
          </div>
        </div>
    </div>
  )
}
const calculateScore = (type:string,id:number,candidateAnswer)=>{
  let score=0;
  if(candidateAnswer){
      score+=candidateAnswer?.score || 0
  }
  return score;
}

const showOptions = (question,Answers:any[])=>{

  const type = question?.type;
  const questionAnswers = type && type === 'text' ? question?.answers : JSON.parse(question?.answers)

  let candidateAnswers = Answers?.find(ans=>ans.questionId === question.id)?.answers 
  candidateAnswers = candidateAnswers && (type !== 'text' ? JSON.parse(candidateAnswers) : candidateAnswers)

  if(type==='single' && Array.isArray(questionAnswers)){

    const answer = 
      <div className="single">
        {questionAnswers?.map((item, index) => {
            const hasCandidateAnswer = candidateAnswers && candidateAnswers.find(ans=>ans.id === item.id);
            return (
              <div key={index} className={`single__options ${
                item?.isCorrect ? "selected" : "non-selected" 
              }`}>
                <div className='single__options__answer'>
                  <input
                    type="radio"
                    className="custom-radio"
                    value={item?.id}
                    disabled
                    checked={hasCandidateAnswer ? true : false}
                    />
                  <label> {item?.name}</label>
                </div>
                <img src={item?.isCorrect ? tick : cross} />
              </div>
            );
          }
        )}
      </div>
    
    return answer
  } else if (type === 'multiple' && Array.isArray(questionAnswers)){
    const answer = 
    <div className="multiple">
      {questionAnswers?.map((item, index) => {
          const hasCandidateAnswer = candidateAnswers && candidateAnswers.find(ans=>ans.id === item.id);
          return (
            <div
              key={index}
              className={`multiple__options answers ${
                item?.isCorrect ? "selected" : "non-selected"
              }`}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  type="checkbox"
                  className="checkbox-checked"
                  value={item?.id}
                  disabled
                  checked={hasCandidateAnswer ? true : false}
                />
                <label> {item?.name}</label>
              </div>
                <img src={item?.isCorrect ? tick : cross} />
            </div>
          );
        }
      )}
    </div>
    
    return answer
  } else if(type === 'text'){
    return <div className="text">
      {candidateAnswers && candidateAnswers}
    </div>
  }
}